import React from 'react'
import {Link} from 'gatsby'

const NosOffres = () => {
    return (
        <section className="pricing-area pt-100 pb-70 bg-f4f7fe">
            <div className="container">
                <div className="section-title">
                    <h2>Nos offres</h2>
                </div>

                <p className='center pb-50'>Pour vous accompagner au mieux dans l'utilisation de  <span className='color-seekreet'>Dolibarr</span>, nous vous proposons une offre clé en main, une offre sur mesure et une offre artisan connecté.</p>
                
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>Offre clé en main</h3>
                            </div>

                            <p className="pricing-features">Nous installons et hébergeons <span className='bold'>rapidement</span> le logiciel pour les <span className='bold'>tâches classiques</span> de gestion commerciale telles que :</p>
                            <ul className="pricing-features">
                                <li><i className='bx bxs-badge-check'></i> Devis</li>
                                <li><i className='bx bxs-badge-check'></i> Factures</li>
                                <li><i className='bx bxs-badge-check'></i> Commandes</li>
                            </ul>
                            <div className="btn-box">
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Plus d'infos 
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>Offre sur mesure</h3>
                            </div>
                            
                            <p className="pricing-features">Nous installons, hébergeons et paramétrons selon vos attentes, des <span className='bold'>modules spécifiques</span> à votre coeur de métier :</p>
                            <ul className="pricing-features">
                                <li><i className='bx bxs-badge-check'></i> Module métiers</li>
                                <li><i className='bx bxs-badge-check'></i> Paramétrage comptable</li>
                                <li><i className='bx bxs-badge-check'></i> Transferts bancaires</li>
                            </ul>

                            <div className="btn-box">
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Plus d'infos 
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>Offre artisans connectés</h3>
                            </div>

                            <p className="pricing-features">
                            Nous installons, hébergeons, et vous accompagnons sur le terrain grâce aux <span className='bold'>tablettes professionnelles</span>.</p>
                            <ul className="pricing-features">
                                <li><i className='bx bxs-badge-check'></i> Gestion commerciale</li>
                                <li><i className='bx bxs-badge-check'></i> Gestion administrative</li>
                                <li><i className='bx bxs-badge-check'></i> Solution matérielle</li>
                            </ul>
                            <div className="btn-box">
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Plus d'infos 
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NosOffres