import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'
import UnProjet from "../components/Common/UnProjet"
import NosOffres from "../components/Dolibarr/NosOffres"
import { Helmet } from "react-helmet"


const OffreDolibarr = () => {
  return (
    <Layout>
      <Helmet>
        <title>Seekreet | Offre Dolibarr</title>
        <meta name="description" content="Automatisez la gestion de vos devis, facture, comptabilité." />
      </Helmet>
      <Navbar />
      <PageBanner
          pageTitle="Offre Dolibarr" 
          homePageText="Accueil" 
          homePageUrl="/" 
          parentActive="Offres" 
          parentPageUrl="/offres"
          activePageText="Offre Dolibarr" 
      />
      <NosOffres />
      <UnProjet />
      <Footer />
    </Layout>
  )
}

export default OffreDolibarr